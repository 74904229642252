<template >
    <div id="video-c">
         <div id="headerHome" class="col-sm-12 col-md-12  color-bg-1">
             
                <HeaderHome :name="user.fullname" /> 
            </div>
            <div class="video-cont">
            <div class="video-sec-cont">
              <div class="videos-cont" v-if="video !='No se encontraron coincidencias' && video !='error'">
                 <div class="last-v-dos">
                      <div class="titulo-u-noticias">
                        <h4>video <span>{{video.title}}</span></h4>
                      <!-- <Sponsor3 :secSponsor3="'VIDEOS'" />-->
                      </div>
                      <div class="last-cont">
                        <VideoModal v-show="isModalVisible3" @close="closeModal3">
                          <template v-slot:header> {{ modalData3.title }} </template>

                          <template v-slot:body> <div v-html="modalData3.url"></div> </template>
                        </VideoModal>
                        <div class="l-cont"  @click="showModal3(video)">
                          <div class="l-div">
                              <div :style="{ backgroundImage: 'url(' + url+'/get-video-image/'+video.image + ')'}" width="377" height="350" alt="e" class="l-m-content"/>
                              <img
                                src="../assets/red-rec.png"
                                width="118"
                                height="12"
                                alt="rr"
                                class="red-rec"
                              />
                              <img src="../assets/play1.png" alt="play" class="play-video-icon">
                          </div>
                          <div class="titulo-vim">
                            <h6 class="date">{{ dataSortDue(video.datevideo) }}</h6>
                            <a>
                              <h4 class="titulo">{{ video.title }}</h4>
                            </a>
                          </div>
                        </div>
                      </div>

                    </div>
                   
            </div>
            <div class="videos-cont" v-else>
               
                 <div class="last-v-dos">
                        <div class="titulo-u-noticias">
                        <h4> Video  <span>no encontrado.</span></h4>
                        <!-- <Sponsor3 :secSponsor3="'VIDEOS'" />-->
                        
                        </div>
                  </div>
            </div>
            </div>
        </div>
            


         <div id="footerSection">
             <Footer />
         </div>
    </div>
</template>

<script>
import Footer from '../components/footer'
import HeaderHome from '../components/HeaderHome';
import { mapActions } from "vuex";

import VueCoreVideoPlayer from "vue-core-video-player";
import Sponsor3 from "../components/widgets/SponsorSecW3";
import es from "dayjs/locale/es";
import relativeTime from "dayjs/plugin/relativeTime";
import customParseFormat from "dayjs/plugin/customParseFormat";
import VideoModal from "../components/VideoModal.vue";


export default {

    components: {
        HeaderHome,
        Footer,

        VueCoreVideoPlayer,
        Sponsor3,
        VideoModal,
          
     
    },
    data(){
        return{
            urlpath:'',
             isModalVisible3: false,
      modalData3: null,

        }
    },
    async beforeMount() {
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
    },
   async created(){
        let token = await this.$store.dispatch("admin/getToken");
        this.$store.dispatch("admin/decode", token);
        


        let id = this.$route.params.url;
            let payload = {
            id: id,
            option: "videos_url",
            };
         await   this.getInfoById(payload);

         if(!this.video.id){
          window.location.href = "/";  
         }


         this.findAll3();
      
    },
    computed: {
        user() {            
            return this.$store.getters['admin/getIdentity'];
        },
        video(){
                return this.$store.getters['main/data'];
        },
         url() {
            return this.$store.getters["main/baseURL"];
         },
  videodata3() {
      let videoda = this.$store.getters["video/data2"];
      let elekipo = videoda.sort(
        (a, b) => {return new Date(b.datevideo) - new Date(a.datevideo);}
      );
      return elekipo;    },

      
    },
    methods:{
            ...mapActions("main", ["getInfoById"]),
             ...mapActions("video", ["findAll"]),
        showModal3(data) {
      this.isModalVisible3 = true;
      this.modalData3 = data;
    },
    closeModal3() {
      this.isModalVisible3 = false;
      this.modalData3 = '';
    },
    dataSortDue: function (date) {
     const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      let newsdata = dayjs(date).locale(es).format("YYYY-MMMM-DD");
     
     let fecha = newsdata.split("-");
      let yy = fecha[0];
      let mm = fecha[1];
      let dd = fecha[2];
      return (fecha = dd  + " DE " + mm + " DEL " + yy);
    },

    videoId: function (url) {
      let match = url.match(/v=([0-9a-z_-]{1,20})/i);
      return match ? match["1"] : false;
    },
    dataTransform: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "-" + mm + "-" + dd);
    },
    dataSort: function (date) {
      let fecha = date.split("/");
      let yy = fecha[2];
      let mm = fecha[1];
      let dd = fecha[0];
      return (fecha = yy + "" + mm + "" + dd);
    },

    newsdateago: function (date) {
      const dayjs = require("dayjs");
      dayjs.extend(customParseFormat);
      dayjs.extend(relativeTime);
      let newsdata = dayjs(date, "YYYY-MM-DD").locale(es).fromNow(true);
      return newsdata;
    },

 get_youtube_thumbnail: function(url, quality){
    if(url){
        var video_id, thumbnail, result;
        if(result = url.match(/youtube\.com.*(\?v=|\/embed\/)(.{11})/))
        {
            video_id = result.pop();
        }
        else if(result = url.match(/youtu.be\/(.{11})/))
        {
            video_id = result.pop();
        }

        if(video_id){
            if(typeof quality == "undefined"){
                quality = 'high';
            }
        
            var quality_key = 'maxresdefault'; // Max quality
            if(quality == 'low'){
                quality_key = 'sddefault';
            }else if(quality == 'medium'){
                quality_key = 'mqdefault';
            } else if (quality == 'high') {
                quality_key = 'hqdefault';
            }

            var thumbnail = "http://img.youtube.com/vi/"+video_id+"/"+quality_key+".jpg";
            return thumbnail;
        }
    }
    return false;
},






    findAll3: async function () {
      let result = await this.$store.dispatch("video/findAll3", {
        option: "videos",
      });
      this.info = result;
      return result;
    },

    filterTitle: function (title) {
      let result = title.replaceAll(" ", "-");
      return result;
    },

    },


  /**titulo pagina */
  watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Galeria - Cimarrones de Sonora';
            }
        },
    }
}
</script>


<style scoped>

#video-c .play-video-icon {
    position: absolute;
    height: 19vw;
    width: 19vw;
   margin-left: 0;
    padding: 7vw;
    z-index: 99;
    display: flex;
    transition: .25s;
    /* align-items: center; */
    /* justify-content: center; */
    /* align-content: center; */
}
.l-m-content {
    background-size: 140%!important;
    background-position-x: center;
    transition: .5s;
}
.l-cont:hover .l-m-content {
    background-size: 180%!important;
}


</style>